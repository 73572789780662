import React, { useEffect, useState } from 'react';
import { useMe } from '../hooks/use-me';

import { Col, Row, Button  } from 'antd';
import { Labels } from '../enums/labels.enum';
import { gql } from '@apollo/client/core';
import { useApolloClient, useMutation, useSubscription } from '@apollo/client';
import {
  logoutMutation,
  logoutMutationVariables,
} from '../__api__/logoutMutation';
import { LOCALSTORAGE_USER_ID } from '../constants';
import { authUserId, isLoggedInVar } from '../apollo';
import { Menu } from './menu';
import { MobileMenu } from './mobile-menu';
import { getFormattedValue } from '../utils/string-utils';
import { NotificationType, Role } from '../__api__/globalTypes';
import { balanceUpdate } from '../__api__/balanceUpdate';
import { totalPurchases } from '../__api__/totalPurchases';
import { notification } from 'antd';
import { createNotification } from '../__api__/createNotification';
import { createAdminNotification } from '../__api__/createAdminNotification';
import { Titles } from '../enums/titles.enum';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

const LOGOUT_MUTATION = gql`
  mutation logoutMutation($input: UserIdInput!) {
    logout(input: $input)
  }
`;

const BALANCE_UPDATE_SUBSCRIPTION = gql`
  subscription balanceUpdate {
    balanceUpdate {
      balance
    }
  }
`;

const TOTAL_PURCHASES_UPDATE_SUBSCRIPTION = gql`
  subscription totalPurchases {
    totalPurchases {
      amount
      monthly_income
      total_income
    }
  }
`;

const NOTIFICATION_SUBSCRIPTION = gql`
  subscription createNotification {
    createNotification {
      actionId
      type
    }
  }
`;

const ADMIN_NOTIFICATION_SUBSCRIPTION = gql`
  subscription createAdminNotification {
    createAdminNotification {
      actionId
      type
    }
  }
`;

export const Header: React.FC = () => {
  //const history = useHistory();
  const { data } = useMe();
  const client = useApolloClient();
  const { data: notificationData } = useSubscription<createNotification>(
    NOTIFICATION_SUBSCRIPTION,
  );
  const {
    data: adminNotificationData,
  } = useSubscription<createAdminNotification>(ADMIN_NOTIFICATION_SUBSCRIPTION);

  const [menuActive] = useState<string | null>(null);

  const { data: balance } = useSubscription<balanceUpdate>(
    BALANCE_UPDATE_SUBSCRIPTION
  );

  const { data: totalOrders } = useSubscription<totalPurchases>(
    TOTAL_PURCHASES_UPDATE_SUBSCRIPTION,
  );

  const openNotification = (
    message: string,
    //notificationType: NotificationType,
  ) => {
    notification.destroy();
    //const key = `open${Date.now()}`;
    /*const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          if (notificationType === NotificationType.NEW_CONSIGNMENT) {
            setMenuActive(Labels.MENU_CONSIGNMENT);
            history.push('/review-consignments');
          }
          if (notificationType === NotificationType.NEW_ORDER) {
            setMenuActive(Labels.MENU_ORDERS);
            history.push('/review-orders');
          }
          if (
            [
              NotificationType.CONSIGNMENT_APPROVED,
              NotificationType.CONSIGNMENT_REJECTED,
            ].includes(notificationType)
          ) {
            setMenuActive(Labels.MENU_CONSIGNMENT);
            history.push('/consignments');
          }
          if (
            [
              NotificationType.ORDER_APPROVED,
              NotificationType.ORDER_REJECTED,
            ].includes(notificationType)
          ) {
            setMenuActive(Labels.MENU_PURCHASES);
            history.push('/purchases');
          }
          notification.close(key);
          window.location.reload();
        }}
      >
        Revisar
      </Button>
    );*/
    notification.info({
      message,
      placement: 'topRight',
      duration: 30,

      //btn,
      //key,
    });
  };

  // Boton para ocultar --------------
  const [isHidden, setIsHidden] = useState(false);

  const toggleVisibility = () => {
    setIsHidden(!isHidden);
  };

  const getDisplayValue = (value) => (isHidden ? "****" : `${getFormattedValue(value)}`);

  // Boton para ocultar --------------

  useEffect(() => {
    client.cache.evict({ fieldName: 'stats' });
    client.cache.evict({ fieldName: 'me' });
    if ([Role.ADMIN, Role.OPERATOR].includes(data?.me.role || Role.OPERATOR)) {
      if (
        adminNotificationData?.createAdminNotification.type ===
        NotificationType.NEW_CONSIGNMENT
      ) {
        openNotification(
          'Nueva acreditación saldo pendiente de revisión',
          //adminNotificationData?.createAdminNotification.type,
        );
      }
      if (
        adminNotificationData?.createAdminNotification.type ===
        NotificationType.NEW_ORDER
      ) {
        openNotification(
          'Nueva acreditación saldo pendiente de revisión',
          //adminNotificationData?.createAdminNotification.type,
        );
      }
    }
    if (
      [Role.MAIN_CUSTOMER, Role.SUBSIDIARY].includes(
        data?.me.role || Role.MAIN_CUSTOMER,
      )
    ) {
      if (
        notificationData?.createNotification.type ===
        NotificationType.CONSIGNMENT_APPROVED
      ) {
        openNotification(
          'Te aprobaron una acreditación de saldo que estaba pendiente de revisión',
          //notificationData?.createNotification.type,
        );
      }
      if (
        notificationData?.createNotification.type ===
        NotificationType.CONSIGNMENT_REJECTED
      ) {
        openNotification(
          'Te rechazaron una acreditación de saldo que estaba pendiente de revisión',
          //notificationData?.createNotification.type,
        );
      }
      if (
        notificationData?.createNotification.type ===
        NotificationType.ORDER_APPROVED
      ) {
        openNotification(
          'Te aprobaron un pedido que estaba pendiente de revisión',
          //notificationData?.createNotification.type,
        );
      }
      if (
        notificationData?.createNotification.type ===
        NotificationType.ORDER_REJECTED
      ) {
        openNotification(
          'Te rechazaron un pedido que estaba pendiente de revisión',
          //notificationData?.createNotification.type,
        );
      }
      if (
        notificationData?.createNotification.type ===
        NotificationType.CONSIGNMENT_REVERSED
      ) {
        openNotification(
          'Se revirtio una acreditación de saldo mal aprobada',
          //notificationData?.createNotification.type,
        );
      }
      if (
        notificationData?.createNotification.type ===
        NotificationType.NEW_DEVOLUTION
      ) {
        openNotification(
          'Tienes un nuevo reclamo pendiente de revisión',
          //notificationData?.createNotification.type,
        );
      }
      if (
        notificationData?.createNotification.type ===
        NotificationType.DEVOLUTION_IN_PROGRESS
      ) {
        openNotification(
          'Tienes un reclamo en proceso de revisión',
          //notificationData?.createNotification.type,
        );
      }
      if (
        notificationData?.createNotification.type ===
        NotificationType.DEVOLUTION_ACCEPTED
      ) {
        updateBalance();
        openNotification(
          'Te a un reclamo que estaba pendiente de revisión',
          //notificationData?.createNotification.type,
        );
      }
      if (
        notificationData?.createNotification.type ===
        NotificationType.DEVOLUTION_REJECTED
      ) {
        openNotification(
          'Te rechazaron un reclamo que estaba pendiente de revisión',
          //notificationData?.createNotification.type,
        );
      }
    }
  }, [notificationData, adminNotificationData]);

  //function clear apollo cache of me query if we get a banace update
  useEffect(() => {
    if (balance) {
      updateBalance();
    }
  }, [balance]);

  const updateBalance = () => {
    client.cache.evict({ fieldName: 'me' });
  }

  const onCompleted = (data: logoutMutation) => {
    const { logout } = data;
    if (logout) {
      localStorage.setItem(LOCALSTORAGE_USER_ID, '');
      authUserId(null);
      isLoggedInVar(false);
      window.location.reload();
    }
  };

  const [logoutMutation, { loading }] = useMutation<
    logoutMutation,
    logoutMutationVariables
  >(LOGOUT_MUTATION, { onCompleted });

  const onLogout = async () => {
    if (!loading) {
      await logoutMutation({
        variables: {
          input: {
            userId: data?.me.id || '',
          },
        },
      });
    }
  };

  return (
    <header>
      <div>
        <div className="bg-gray-100">
          <nav data-todo-x-data="{ open: false }" className="bg-white shadow">
            <Menu
              role={data?.me.role}
              onLogout={onLogout}
              menuActive={menuActive}
            />
            <MobileMenu
              fullName={
                data?.me.customer
                  ? `${data.me.customer.name} ${data.me.customer.lastName}`
                  : ''
              }
              email={data?.me.email}
              role={data?.me.role}
              onLogout={onLogout}
              menuActive={menuActive}
            />
          </nav>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="px-2 py-3 mx-auto max-w-7xl sm:px-4 lg:px-4">
          <div>
            <h3 className="text-lg font-medium text-gray-900 leading-4">
              {Labels.WELCOME + Titles.LANDING}
            </h3>
            {[Role.SUBSIDIARY, Role.MAIN_CUSTOMER].includes(
              data?.me.role || Role.ADMIN,
            ) && (
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
                  <div className="px-1 py-1 overflow-hidden bg-white rounded-lg shadow sm:p-2">
                    <dt className="text-sm font-medium text-gray-500 truncate items-center">
                      {Labels.AVAILABLE_BALANCE}
                      <Button
                        type="text"
                        icon={isHidden ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        onClick={toggleVisibility}
                        className="ml-2"
                      />
                    </dt>
                    <dd className="mt-1 text-3xl font-semibold text-gray-900">
                      $
                      {getDisplayValue(
                        balance?.balanceUpdate.balance ||
                        data?.me.customer?.balance ||
                        0,
                      )}
                    </dd>
                  </div>
                  <div className="px-1 py-1 overflow-hidden bg-white rounded-lg shadow sm:p-2">
                    <Row>
                      <Col span={8} sm={24} lg={8} xs={24}>
                        <dt className="text-sm font-medium text-gray-500 truncate">
                          {Labels.TOTAL_SALES}
                        </dt>
                        <dd className="mt-1 text-3xl font-semibold text-gray-900">
                          $
                          {getDisplayValue(
                            totalOrders?.totalPurchases.amount ||
                            data?.me.customer?.totalPurchases ||
                            0,
                          )}
                        </dd>
                      </Col>
                      <Col span={8} sm={24} lg={8} xs={24}>
                        <dt className="text-sm font-medium text-gray-500 truncate">
                          {Labels.MONTHLY_INCOME}
                        </dt>
                        <dd className="mt-1 text-3xl font-semibold text-gray-900">
                          $
                          {getDisplayValue(
                            totalOrders?.totalPurchases.monthly_income ||
                            data?.me.customer?.monthlyIncome ||
                            0,
                          )}
                        </dd>
                      </Col>
                      <Col span={8} sm={24} lg={8} xs={24}>
                        <dt className="text-sm font-medium text-gray-500 truncate">
                          {Labels.TOTAL_INCOME}
                        </dt>
                        <dd className="mt-1 text-3xl font-semibold text-gray-900">
                          $
                          {getDisplayValue(
                            totalOrders?.totalPurchases.total_income ||
                            data?.me.customer?.totalIncome ||
                            0,
                          )}
                        </dd>
                      </Col>
                    </Row>

                  </div>
                </dl>
              )}
          </div>
        </div>
      </div>
    </header>
  );
};
