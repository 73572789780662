import React from 'react';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import { FormError } from '../../components/form-error';
import { useForm } from 'react-hook-form';
import { Labels } from '../../enums/labels.enum';
import { Button } from '../../components/button';
import { Exceptions } from '../../enums/exceptions.enum';
import { checkLength } from '../../utils/validate-utils';
import {
  EMAIL_REGEX,
  INPUT_TEXT_REGEX,
  VALID_NUMBER_REGEX,
  VALID_TEXT_NUMBER_REGEX,
} from '../../constants';
import { roundWithTwoDecimals } from '../../utils/numeric-utils';
import { productsByCategoryId_productsByCategoryId } from '../../__api__/productsByCategoryId';

interface IBuyProductProps {
  onOk: (details: any) => void;
  product: productsByCategoryId_productsByCategoryId | null;
}

interface IField {
  label: string;
  name: string;
  dataType: string;
}


export const BuyProduct: React.FC<IBuyProductProps> = ({ onOk, product }) => {

  const {
    register,
    getValues,
    errors,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = async () => {
    const { ...input } = getValues();
    const orderDetail = {
      fields: Object.keys(input).map((key: string) => ({
        name: key,
        value: input[key],
      })),
    };
    onOk(orderDetail);
  };

  const patternByDataType = (dataType: string) =>
    dataType === 'email'
      ? {
        value: EMAIL_REGEX,
        message: FormErrorMessages.VALID_EMAIL,
      }
      : dataType === 'number'
        ? {
          value: VALID_NUMBER_REGEX,
          message: FormErrorMessages.INVALID_NUMBER,
        }
        : dataType === 'text_number'
          ? {
            value: VALID_TEXT_NUMBER_REGEX,
            message: FormErrorMessages.INVALID_DATA,
          }
          : {
            value: INPUT_TEXT_REGEX,
            message: FormErrorMessages.INVALID_DATA,
          };

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="flex-1 min-w-0">
          <div className="flex items-center mb-4 space-x-3">
            <h2 className="text-lg font-bold text-gray-900 leading-7">
              {product?.name}
            </h2>
            {(product?.discount || 0) > 0 && (
              <span className="flex-shrink-0 inline-block px-2 text-base font-medium text-red-500 line-through bg-orange-200 rounded-full py-0.5">
                $ {roundWithTwoDecimals(product?.price || 0)}
              </span>
            )}
            <span className="flex-shrink-0 inline-block px-2 text-base font-medium text-blue-900 bg-blue-200 rounded-full py-0.5">
              $ {roundWithTwoDecimals(product?.priceWithDiscount || 0)}
            </span>
          </div>
          <h3 className="mb-6 text-base font-semibold text-gray-900 leading-7">
            {product?.description}
            <br />
            {((product?.pvp || 0) > 0) && (<div style={{ border: "2px solid red", width: "fit-content", borderRadius: "10px", padding: "2px" }}>Precio de Venta: <span style={{ fontWeight: "bold", fontSize: "1rem" }}>${roundWithTwoDecimals(product?.pvp || 0)}</span></div>)}

          </h3>
          <h4 className="mb-2 text-sm font-normal text-gray-900 leading-5">
            Requerimos de la siguiente información para la compra de tu producto a
          </h4>
        </div>
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            {product?.requiredInfo.fields.map((field: IField) => {
              return field.dataType === 'text_number' ? (
                <div key={field.name}>
                  <label htmlFor={field.name} className="label">
                    {field.label}
                  </label>
                  <div className="mt-1">
                    <input
                      ref={register({
                        required: FormErrorMessages.REQUIRED_FIELD,
                        pattern: patternByDataType(field.dataType),
                      })}
                      name={field.name}
                      onKeyDown={(e) => checkLength(e)}
                      className="input"
                      step=".01"
                      style={{ fontSize: '25px', padding: '10px', fontWeight: 'bold'}} // Aumenta el tamaño del texto y el espacio interno y añade negrita
                    />
                    {errors[field.name]?.message && (
                      <FormError errorMessage={errors[field.name]?.message} />
                    )}
                  </div>
                </div>
              ) : (
                <div key={field.name}>
                  <label htmlFor={field.name} className="label">
                    {field.label}
                  </label>
                  <div className="mt-1">
                    <input
                      ref={register({
                        required: FormErrorMessages.REQUIRED_FIELD,
                        pattern: patternByDataType(field.dataType),
                      })}
                      name={field.name}
                      type={field.dataType}
                      className="input"
                      step=".01"
                    />
                    {errors[field.name]?.message && (
                      <FormError errorMessage={errors[field.name]?.message} />
                    )}
                  </div>
                </div>
              );
            })}
            <Button
              canClick={isValid}
              loading={false}
              actionText={Labels.BUY}
            />
            {errors && (
              <FormError errorMessage={Exceptions[errors.message as any]} />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
